import React from 'react'
import { RouteConfig } from '../../routesOld'
const CreateArtist = React.lazy(
  () => import('../CreateProfile/CreateArtist/CreateArtist'),
)
const CreateVenue = React.lazy(
  () => import('../CreateProfile/CreateVenue/CreateVenue'),
)
const ProfileSettings = React.lazy(() => import('./ProfileSettings'))
const EditPermissions = React.lazy(
  () => import('./ProfileSettings/Permissions/EditPermissions'),
)
const InviteMember = React.lazy(
  () => import('./ProfileSettings/Permissions/InviteMember'),
)
const EditProfile = React.lazy(
  () => import('./ProfileSettings/ProfileDetails/EditProfile'),
)
const Security = React.lazy(() => import('./Security/Security'))
import { manageAccountRouteConfig } from './Tabs/ManageAccount/ManageAccount'

type SettingsRoute = RouteConfig & {
  omitNavButton?: boolean
}

export const settingsRouteConfigs: SettingsRoute[] = [
  manageAccountRouteConfig,
  {
    label: 'Security',
    path: 'security',
    element: <Security />,
    iconName: 'fa fa-key',
  },
  {
    label: 'Create artist',
    path: 'create-artist',
    element: <CreateArtist />,
    iconName: 'fa fa-music',
  },
  {
    label: 'Create venue',
    path: 'create-venue',
    element: <CreateVenue />,
    iconName: 'fa fa-map-marker-alt',
  },
  {
    path: 'profiles/:slug',
    label: 'Profile settings',
    element: <ProfileSettings />,
    omitNavButton: true,
  },
  {
    path: 'profiles/:slug/edit',
    label: 'Edit profile details',
    element: <EditProfile />,
    omitNavButton: true,
  },
  {
    path: 'profiles/:profileSlug/permissions/:userId',
    label: 'Edit permissions',
    element: <EditPermissions />,
    omitNavButton: true,
  },
  {
    path: 'profiles/:profileSlug/invite',
    label: 'Invite member',
    element: <InviteMember />,
    omitNavButton: true,
  },
]

export function createEditPermissionsPath(profileSlug: string, userId: string) {
  return `/settings/profiles/${profileSlug}/permissions/${userId}`
}

export function createInviteMemberPath(profileSlug: string) {
  return `/settings/profiles/${profileSlug}/invite`
}

export function getProfileSettingsUrlFromSlug(slug: string): string {
  return `/settings/profiles/${slug}`
}

export function getEditProfileSettingsUrlFromSlug(slug: string): string {
  return `/settings/profiles/${slug}/edit`
}

export function getSceneUrl(slug: string): string {
  return `/scene/${slug}`
}
