import { MetaFunction } from '@remix-run/cloudflare'
import * as styles from './ManageAccount.styles'
import { SelectCityMutatingCompact } from '../../../../components/forms/SelectCity/SelectCity'
import { SettingsBackButton } from '../../SettingsBackButton'
import { SessionSummary } from './SessionSummary'
import { RouteConfig } from '../../../../routesOld'

export const meta: MetaFunction = () => [
  { name: 'title', content: 'Settings' },
  { name: 'og:title', content: 'Settings' },
]

const ManageAccount: React.FC = () => {
  return (
    <section>
      <section css={styles.container}>
        <SettingsBackButton />

        <h1>Manage account</h1>

        <section>
          <h3>City</h3>
          <SelectCityMutatingCompact />
        </section>

        <SessionSummary />
      </section>
    </section>
  )
}

export const manageAccountRouteConfig: RouteConfig = {
  label: 'Account',
  path: 'account',
  element: <ManageAccount />,
  iconName: 'fa fa-address-card',
}

export default ManageAccount
